import { InitOptions } from 'i18next'

export const Locales = {
  'en-GB': 'en-GB',
  'en-NZ': 'en-NZ',
  'en-AU': 'en-AU',
  'de-DE': 'de-DE',
  'fr-FR': 'fr-FR',
  'es-ES': 'es-ES',
} as const

export type Locales = (typeof Locales)[keyof typeof Locales]

export const NameSpaces = {
  global: 'global',
  eiv: 'eiv',
  swf: 'swf',
} as const

export type NameSpaces = (typeof NameSpaces)[keyof typeof NameSpaces]

export const locales = Object.values(Locales)

export const fallbackLocale = Locales['en-GB']
export const defaultNS = NameSpaces.global

export function getOptions(lng: Locales = fallbackLocale, ns: NameSpaces[] = [defaultNS]) {
  return {
    // debug: true,
    supportedLngs: locales,
    fallbackLng: fallbackLocale,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    // Loads all namespaces on init. Reduces the possibility of resources not
    // loaded halfway through completing the form (e.g. when new build is deployed)
    ns,
    returnEmptyString: false,
  } as InitOptions
}
