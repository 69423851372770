// Need to import like this due to Jest error: https://github.com/date-fns/date-fns/issues/3674
import * as dateFns from 'date-fns'
import { de, enGB, fr, es } from 'date-fns/locale'

import { Locales } from '@modules/i18n/settings'

const APP_LOCALE_TO_DATE_LOCALE: Record<Locales, dateFns.Locale> = {
  'en-GB': enGB,
  'en-NZ': enGB,
  'en-AU': enGB,
  'de-DE': de,
  'fr-FR': fr,
  'es-ES': es,
}

export const formatDate = (date: number | Date, formatStr: string = 'PPP', locale: Locales = 'en-GB') =>
  dateFns.formatDate(date, formatStr, {
    locale: APP_LOCALE_TO_DATE_LOCALE[locale],
  })
