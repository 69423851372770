import { Scalars } from '@api'

export type BusinessDetails = {
  name: string
  id: string
}

export enum CountryCode {
  NewZealand = 'NZ',
  Australia = 'AU',
  GreatBritain = 'GB',
}

export const firstAMLBusinessDetails = {
  [CountryCode.Australia]: {
    name: 'First AML Pty Limited',
    id: '643929140',
  },
  [CountryCode.GreatBritain]: {
    name: 'First AML UK Limited',
    id: '13802565',
  },
  [CountryCode.NewZealand]: {
    name: 'First AML Limited',
    id: '6553092',
  },
}

export const determineFirstAMLSpecificJurisdictionBusinessDetails = (
  country: (Scalars['CountryCode']['output'] & CountryCode) | undefined
): BusinessDetails => {
  if (!country) {
    return firstAMLBusinessDetails[CountryCode.NewZealand]
  }

  return firstAMLBusinessDetails[country]
}
