'use client'

import { createInstance } from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Sentry from '@sentry/nextjs'

import resourcesToBackend from 'i18next-resources-to-backend'
import { Locales, NameSpaces, getOptions } from './settings'
import { hasRecentlyReloaded } from './hasRecentlyReloaded'

type Options = {
  onResourceLoadFail?: VoidFunction
}

export const i18nextInstance = async (locale: Locales, namespaces: NameSpaces[], options?: Options) => {
  const i18nInstance = createInstance()
  let numberOfErrors = 0

  if (window.__FAML_FORMS_CONFIG__.ENVIRONMENT === 'local') {
    const Pseudo = await import('i18next-pseudo')
    i18nInstance.use(
      new Pseudo.default({
        enabled: true,
        languageToPseudo: Locales['en-NZ'],
      })
    )
  }

  i18nInstance
    .use(initReactI18next)
    .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)))
    .on('failedLoading', (language, namespace, error) => {
      numberOfErrors++
      // Only log the first error
      if (numberOfErrors > 1) {
        return
      }

      if (hasRecentlyReloaded()) {
        // Page reloads when there's error fetching resources. If it's still failing after a reload, log the error
        Sentry.captureMessage(`Error loading resources for ${language} and ${namespace}. ${error}`)
        options?.onResourceLoadFail?.()
      } else {
        window.location.reload()
      }
    })

  i18nInstance.init({
    ...getOptions(locale, namespaces),
    postProcess: ['pseudo'],
  })

  // Return false if there was an error loading i18next settings
  return i18nInstance
}
