import { checkIfDateIsValid } from './checkIfDateIsValid'
import { convertToTwoLettersString } from './covertToTwoLettersString'

/**
 * Use this function to convert date from BE to InputDate type
 * BE date format: YYYY-MM-DDTHH-mm-ss.SSSZ or YYYY-MM-DD
 */
export const convertStringDateToObject = (date: string) => {
  const isValid = checkIfDateIsValid(date)

  if (!isValid) {
    return
  }

  const dateObj = new Date(date)

  return {
    day: convertToTwoLettersString(dateObj.getDate()),
    month: convertToTwoLettersString(dateObj.getMonth() + 1),
    year: dateObj.getFullYear().toString(),
  }
}
