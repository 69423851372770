'use client'

import styled from 'styled-components'

export const AppContainer = styled.div`
  max-width: 560px;
  margin: 0 auto;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.m};
`
