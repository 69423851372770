import { usePathname, useSearchParams } from 'next/navigation'
import { Locales } from '@modules/i18n/settings'

type LocalesWithEmpty = Locales | ''

export const useGetUrl = () => {
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const localeRegex = /\/[a-z]{2}-[A-Z]{2}/

  const pathNameWithoutLocale = pathName.replace(localeRegex, '')
  // I've decided to use locale from path instead of window config because the path locale does not always equal to window config
  // Eg: <baseUrl>/eiv does not have a locale in its url but window config uses the fallback locale
  // Visualisation of what the below is doing: '/en-NZ/eiv' -> '/en-NZ' -> 'en-NZ
  const localeFromPath = pathName.split(pathNameWithoutLocale)[0].replace('/', '') as LocalesWithEmpty

  const getCurrentPathWithLocale = (locale: LocalesWithEmpty = localeFromPath) => {
    const path = searchParams.toString() ? `${pathNameWithoutLocale}?${searchParams.toString()}` : pathNameWithoutLocale

    return locale ? `/${locale}${path}` : path
  }

  const generatePathWithLocale = (path: string, locale: LocalesWithEmpty = localeFromPath) => {
    return locale ? `/${locale}${path}` : path
  }

  return {
    getCurrentPathWithLocale,
    generatePathWithLocale,
  }
}
