'use client'

import { ReactNode, useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { i18n } from 'i18next'

import { i18nextInstance } from '@modules/i18n/i18nextInstance'
import { Locales, NameSpaces } from '@modules/i18n/settings'
import { ErrorPage } from '@components'
import { LoadingSpinner } from '@faml/faml-ui'

type Props = {
  locale: Locales
  children: ReactNode
  namespaces: NameSpaces[]
}

export const I18nProvider = ({ locale, children, namespaces }: Props) => {
  const [i18nSettings, setI18nSettings] = useState<i18n | false | null>(null)

  useEffect(() => {
    window.__FAML_FORMS_CONFIG__.LOCALE = locale
  }, [locale])

  useEffect(() => {
    const initI18n = async () => {
      const options = {
        onResourceLoadFail: () => setI18nSettings(false),
      }
      const settings = await i18nextInstance(locale, namespaces, options)
      setI18nSettings(settings)
    }
    initI18n()
  }, [locale, namespaces])

  // If i18nSettings is false, it means there was an error loading the i18n settings
  if (i18nSettings === false) {
    return <ErrorPage variant="generic" />
  }

  // If i18nSettings is null, it means the i18n settings are still loading
  if (!i18nSettings) {
    return <LoadingSpinner isFullPage />
  }

  return (
    <I18nextProvider i18n={i18nSettings} defaultNS={namespaces[0]}>
      {children}
    </I18nextProvider>
  )
}
