import { ReactNode, Component, ErrorInfo } from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/nextjs'

import { ErrorPage } from './ErrorPage'

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to Sentry
    Sentry.withScope((scope) => {
      scope.setExtras({ ...errorInfo })
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <ErrorContainer>
          <ErrorPage variant="generic" />
        </ErrorContainer>
      )
    }

    return this.props.children
  }
}

export const ErrorContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
