// TODO: This is a copy of the one from CDD App and the intention to
// eventually use a shared util
import { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

export const useViewport = () => {
  const currentTheme = useTheme()
  const isDesktop = useMediaQuery(`screen and (min-width: ${currentTheme.breakpoints.desktop})`)
  const isTablet = useMediaQuery(`screen and (min-width: ${currentTheme.breakpoints.tablet})`)
  const isMobile = !isDesktop && !isTablet

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    const body = document.querySelector('body')
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    if (body && window.ResizeObserver) {
      const observer = new ResizeObserver(() => setMatches(media.matches))
      observer.observe(body)
      return () => observer.unobserve(body)
    }
  }, [query])

  return matches
}
