import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => css`
    html,
    body {
      height: 100%;
      padding: 0;
      margin: 0;
      background-color: ${theme.backgroundColours.primary};
      color: ${theme.brandColours.blue};
      line-height: ${theme.lineHeight.m};
      -webkit-font-smoothing: antialiased;
      font-family: ${theme.fontFamily};
    }

    * {
      box-sizing: border-box;
    }

    ul {
      margin: 0;
      list-style: none;
    }
  `
)

export const GlobalFontStyles = createGlobalStyle(
  ({ theme }) => css`
    html,
    body {
      font-family: ${theme.fontFamily};
    }
  `
)
