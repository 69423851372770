import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'

const useInitialiseDataDogRUM = (frontendSessionId: string | undefined) => {
  useEffect(() => {
    const environment = window.__FAML_FORMS_CONFIG__.ENVIRONMENT
    const isProduction = environment === 'production' || environment === 'eu-production'
    const isTest = environment === 'development' || environment === 'staging'

    // Datadog RUM only runs in the following environments
    if (isProduction || isTest) {
      datadogRum.init({
        applicationId: '9818c1a7-8d41-49a3-9ce6-5cc5d6b67923',
        // Client token is not private (will be in FE bundle regardless of where we store it)
        clientToken: 'pub8054aeb40f938960bac5be0301b5849f',
        site: 'datadoghq.com',
        service: 'forms',
        // This is supplied by Buildkite at compile time
        version: process.env.bundleReleaseVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        // Don't send internal errors to DataDog
        telemetrySampleRate: 0,
        env: window.__FAML_FORMS_CONFIG__.ENVIRONMENT,
        defaultPrivacyLevel: 'mask',
      })
    }
  }, [])

  // Attach a session ID when available
  useEffect(() => {
    if (frontendSessionId) {
      datadogRum.setGlobalContextProperty('frontendSessionId', frontendSessionId)

      return () => {
        datadogRum.removeGlobalContextProperty('frontendSessionId')
      }
    }
  }, [frontendSessionId])
}

export { useInitialiseDataDogRUM }
