const ISO_DATE_REGEX = /\d{4}-\d{2}-\d{2}/
const ISO_DATE_REGEX_WITH_TIME = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/

export const checkIfDateIsValid = (dateString: string) => {
  if (!dateString.match(ISO_DATE_REGEX) && !dateString.match(ISO_DATE_REGEX_WITH_TIME)) {
    return false
  }

  try {
    const date = new Date(dateString)
    const isFormattedDateSameAsInitialDate =
      date.toISOString().slice(0, 10) === dateString || date.toISOString() === dateString

    return isFormattedDateSameAsInitialDate
  } catch {
    return false
  }
}
