export type FetchHeaders = Record<string, string>

type FetchDataParams = {
  url: string
  headers?: FetchHeaders
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  body?: string
  timeout?: number
  abortController?: AbortController
}

export const fetchData = async <TData>({
  url,
  method = 'GET',
  headers,
  body,
  timeout,
  abortController: parentAbortController,
}: FetchDataParams) => {
  const abortController = parentAbortController ?? new AbortController()
  const timeoutId = timeout ? setTimeout(() => abortController.abort(), timeout) : null

  const response = await window.fetch(url, {
    method,
    headers,
    body,
    signal: abortController ? abortController.signal : null,
  })

  if (timeoutId) {
    clearTimeout(timeoutId)
  }

  if (!response.ok) {
    const error = {
      name: 'Network error',
      message: `Failed to fetch: ${response.status} ${response.statusText}`,
    }

    throw error
  }

  const data: TData = await response.json()

  return data
}
