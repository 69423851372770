import { useEffect, useState } from 'react'
import { nanoid as generateUniqueId } from 'nanoid'

/** Generates a unique session ID (i.e. one per mount of the consuming component), attaches it to the window and cleans up after itself */
export const useGenerateSessionId = () => {
  const [sessionId, setSessionId] = useState<string>()

  // Note: we use an effect here primarily to ensure we only generate a new sessionId on mount (rather than rerender)
  useEffect(() => {
    const sessionId = generateUniqueId()

    setSessionId(sessionId)

    window.__FAML_FORMS_CONFIG__.SESSION_ID = sessionId

    return () => {
      window.__FAML_FORMS_CONFIG__.SESSION_ID = undefined
    }
  }, [])

  return sessionId
}
