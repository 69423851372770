// Need to import like this due to Jest error: https://github.com/date-fns/date-fns/issues/3674
import * as dateFns from 'date-fns'
import { checkIfDateIsValid } from '@utils'
import { InputDate } from '../modules/eiv-form/types'
import { convertToTwoLettersString } from './covertToTwoLettersString'
import { TFunction } from 'i18next'

export const getAge = (t: TFunction, { year, month, day }: InputDate) => {
  const inputDateToString = `${year}-${convertToTwoLettersString(month)}-${convertToTwoLettersString(day)}`
  const isValidDate = checkIfDateIsValid(inputDateToString)

  if (!isValidDate) {
    // Date will be invalid when the user has not finish typing their birthday
    return ''
  }

  const isoBirthday = dateFns.parseISO(inputDateToString)
  const today = new Date()
  const isBornToday = dateFns.differenceInDays(today, isoBirthday) === 0
  const isTimeTraveller = isoBirthday > today

  if (isBornToday) {
    return t('get_age.born_today', { ns: 'global' })
  }

  if (isTimeTraveller) {
    return t('get_age.born_in_future', { ns: 'global' })
  }

  const age = dateFns.differenceInYears(today, isoBirthday).toString()

  return age
}
