import { useCallback, useEffect, useRef, useState } from 'react'
import { GqlRequestArgs, graphQLRequest, UnknownObject } from './graphqlRequest'

type RequestError = {
  name: string
  message: string
}

export const useGraphqlRequest = <TData extends UnknownObject, TVariables extends UnknownObject>({
  query,
  variables,
}: GqlRequestArgs<TVariables>) => {
  const variablesRef = useRef<TVariables>(variables)
  const [data, setData] = useState<TData>()
  const [error, setError] = useState<RequestError | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const runRequest = useCallback(async () => {
    try {
      const result = await graphQLRequest<TData, TVariables>({
        variables: variablesRef.current,
        query,
      })

      setData(result.data)
      setError(null)
    } catch (error: unknown) {
      setError(error as RequestError)
    } finally {
      setIsLoading(false)
    }
  }, [query])

  useEffect(() => {
    setIsLoading(true)
    runRequest()
  }, [runRequest])

  return { data, isLoading, error, runRequest }
}
