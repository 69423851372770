export type UploadingFailureStatus =
  | 'error'
  | 'error-case-task-deleted'
  | 'error-case-task-completed'
  | 'error-entity-not-found'
  | 'error-individual-not-found'

export type UploadingStatus = 'starting' | 'started' | 'completed' | 'aborted' | 'error'

type FileUploaderArgs = {
  request: XMLHttpRequest
  file: File
  url: string
  onProgress: (bytesLoaded: number) => void
  onStatusChange: (status: UploadingStatus) => void
  customHeaders?: string[]
}

export const uploadDocument = ({ request, file, url, onProgress, onStatusChange, customHeaders }: FileUploaderArgs) => {
  request.addEventListener('load', () => {
    if (request.status !== 200) {
      onStatusChange('error')

      return
    }

    onStatusChange('completed')
  })

  request.upload.addEventListener('loadstart', () => {
    onStatusChange('started')
  })

  request.upload.addEventListener('progress', ({ loaded }) => {
    onProgress(loaded)
  })

  request.upload.addEventListener('error', () => {
    onStatusChange('error')
  })

  request.upload.addEventListener('abort', () => {
    onStatusChange('aborted')
  })

  request.open('PUT', url)
  request.setRequestHeader('content-type', file.type)

  if (customHeaders) {
    customHeaders.forEach((header) => {
      const [name, value] = header.split(',')
      request.setRequestHeader(name, value)
    })
  }

  request.send(file)
}
