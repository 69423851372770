// Need to import like this due to Jest error: https://github.com/date-fns/date-fns/issues/3674
import * as dateFns from 'date-fns'
const NEXT_RELOAD_TIME = 'nrt'

export const hasRecentlyReloaded = () => {
  const now = new Date()
  const parameters = window.location.search
  const parametersObject = searchParamsToObject(parameters)

  // Typecasting to string | undefined because the value could be undefined
  const nextReloadTime = parametersObject[NEXT_RELOAD_TIME] as string | undefined

  // If recently reloaded date is in the future, it means the user has recently reloaded
  const hasRecentlyReloaded = !!nextReloadTime && dateFns.isBefore(now, new Date(nextReloadTime))

  // If the user has recently reloaded, return true
  if (hasRecentlyReloaded) {
    return true
  }

  // If user has not recently reloaded, set the next reload time 10 minutes from now and return false
  const tenMinutesFromNow = dateFns.add(now, { minutes: 10 }).toISOString()
  // Set the next reload time in the URL
  // Adding a new parameter to the query string can force the page to refetch from the s3 bucket
  const currentPath = window.location.origin + window.location.pathname
  const newParameters = parameters
    ? `${parameters}&${NEXT_RELOAD_TIME}=${tenMinutesFromNow}`
    : `?${NEXT_RELOAD_TIME}=${tenMinutesFromNow}`

  window.history.replaceState(null, '', `${currentPath}${newParameters}`)

  return false
}

const searchParamsToObject = (queryString: string) => Object.fromEntries(new URLSearchParams(queryString).entries())
