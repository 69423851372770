import * as Sentry from '@sentry/nextjs'

export const initialiseSentry = () => {
  const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
  const environment = window.__FAML_FORMS_CONFIG__.ENVIRONMENT

  const isSentryEnabled = environment !== 'local' && environment !== 'ci-testing'

  Sentry.init({
    dsn: SENTRY_DSN || 'https://76ef2c88ca1d48d2adc27c8c9f9f7c16@o256463.ingest.sentry.io/6043195',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: environment === 'production' || environment === 'eu-production' ? 0.5 : 0.0,
    enabled: isSentryEnabled,
    environment,
  })
}
