import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'

import { useGenerateSessionId } from './useGenerateSessionId'
import { useInitialiseDataDogRUM } from './useInitialiseDataDogRUM'

export const useInitialiseTracking = () => {
  // Generate a new session ID and plops it on the window
  const sessionId = useGenerateSessionId()

  // Add Session ID tag to Sentry
  useEffect(() => {
    if (sessionId) {
      Sentry.setTag('frontendSessionId', sessionId)
    }
  }, [sessionId])

  // Enables real user monitoring via DataDog RUM (tracks session ID)
  useInitialiseDataDogRUM(sessionId)
}
